import 'lazysizes';
var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
$().ready(function(){    
    setupSlideMenu();
    submenuPadding();
    $('#map-click').click(function(){
        // shield the map on mobile for easy scrolling
        $(this).hide();
    });
});

export function setupSlideMenu(){

    $('#mobile-menu, .slide-menu h3').click(function(){
        $("body").toggleClass('slide-menu-active');
    });

    $(".slide-menu").on('click','.expand',function(e) {
        e.stopPropagation();
        $(this).closest('li').toggleClass('expand-active');
    });

    $(".slide-menu li").each(function(index, element) {
        var $el = $(element);
        if ($el.find('ul').length > 0) {
            var expander = $('<span class="expand"><i class="nav-arrow"></i></span>');
            $el.append(expander);
            expander.click(function(e) {
                e.stopPropagation();
                $el.toggleClass('expand-active');
            });
        }
    });
}


export function submenuPadding(){
/*
	calculate the height of the tallest submenu
	& add that padding to the top of the content.
	Assuring that submenu text cannot overlap page banner/content text.
*/
	var header_submenus = document.querySelectorAll("header li.depth-0.has-sub > ul");
	if (header_submenus){
		var maxHeight = 0;
		header_submenus.forEach(el => {
			// these are display none, so copy them to find their natural height
			var clone = el.cloneNode(true);
			el.after(clone);
			clone.style.display = "block";
			var height = Math.ceil(clone.getBoundingClientRect().height);
			clone.remove();
			maxHeight = (height > maxHeight)? height: maxHeight;
		});

		var header = document.querySelector("header.top");
		var head_wrap = header.parentNode;

		if (head_wrap){
			var subTarget = head_wrap.getAttribute("data-subTarget");
			if (subTarget){
				maxHeight = (maxHeight > 0)? maxHeight + "px" : "10vw";
				var target_elem = document.querySelector(subTarget);
				if (target_elem){
					target_elem.style.paddingTop = maxHeight;
				}
			} else {
				header.style.paddingBottom = maxHeight + "px";
			}
		} else {
			header.style.paddingBottom = maxHeight + "px";
		}
	}
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener('lazybeforeunveil', function(e){
    var container = e.target;
    var bgUrl = container.dataset.bgUrl;
    if (bgUrl) {
        container.style.backgroundImage = "url('" + bgUrl + "')";
        delete container.dataset.bgUrl;
    }
});
// implementation of https://danpaquette.net/read/automatically-resizing-a-list-of-icons-or-logos-so-theyre-visually-proportional/
function proportionalImageNormalisation(selector, widthBase=50, scaleFactor=0.525){  
    var elements = document.querySelectorAll(selector);
  
    function adjustImageWidth(element) {
        var imageRatio;
        if (element instanceof HTMLImageElement) {
            imageRatio = element.naturalWidth / element.naturalHeight;
            element.style.width = (Math.pow(imageRatio, scaleFactor) * widthBase) + 'px';
        } else {
            imageRatio = element.clientWidth / element.clientHeight;
            element.style.width = (Math.pow(imageRatio, scaleFactor) * widthBase) + 'px';
        }
    }
    elements.forEach(adjustImageWidth);
}

window.onload = function() { 
    proportionalImageNormalisation(".banners-partners .banner-inner-image", 40);
};